@charset "UTF-8";
#top {
  line-height: 1.6;
  letter-spacing: 0.05em;
}

#top html, #top body, #top div, #top span, #top applet, #top object, #top iframe, #top h1, #top h2, #top h3, #top h4, #top h5, #top h6, #top p, #top blockquote, #top pre, #top a, #top abbr, #top acronym, #top address, #top big, #top cite, #top code, #top del, #top dfn, #top em, #top img, #top ins, #top kbd, #top q, #top s, #top samp, #top small, #top strike, #top strong, #top sub, #top sup, #top tt, #top var, #top b, #top u, #top i, #top center, #top dl, #top dt, #top dd, #top ol, #top ul, #top li, #top fieldset, #top form, #top label, #top legend, #top table, #top caption, #top tbody, #top tfoot, #top thead, #top tr, #top th, #top td, #top article, #top aside, #top canvas, #top details, #top embed, #top figure, #top figcaption, #top footer, #top header, #top hgroup, #top menu, #top nav, #top output, #top ruby, #top section, #top summary, #top time, #top mark, #top audio, #top video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

#top article, #top aside, #top details, #top figcaption, #top figure, #top footer, #top header, #top hgroup, #top menu, #top nav, #top section {
  display: block;
}

#top body {
  line-height: 1;
}

#top ol, #top ul {
  list-style: none;
}

#top blockquote, #top q {
  quotes: none;
}

#top blockquote::before, #top blockquote::after, #top q::before, #top q::after {
  content: '';
  content: none;
}

#top table {
  border-collapse: collapse;
  border-spacing: 0;
}

#top input, #top select, #top button, #top textarea {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

#top select::-ms-expand {
  display: none;
}

#top *, #top *::before, #top *::after {
  box-sizing: border-box;
}

#top a {
  color: #333333;
  text-decoration: none;
  overflow-wrap: break-word;
  transition: 0.3s ease-out;
}

#top a:visited {
  color: #333333;
}

#top a:hover {
  color: #333333;
}

#top a:active {
  color: #333333;
}

#top a[href*="tel:"] {
  text-decoration: none;
  pointer-events: none;
  cursor: default;
}

#top img {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

#top img:not(.no-fit) {
  width: 100%;
}

#top .list__item {
  margin-left: 1em;
  text-indent: -1em;
}

#top .list__item:not(:nth-last-of-type(1)) {
  margin-bottom: 4px;
}

#top .list__item::before {
  content: '・';
}

#top .search {
  margin-bottom: calc(8px * 6);
}

@media screen and (max-width: 960px) {
  #top .search {
    margin-bottom: calc((8px * 6) * 0.75);
  }
}

@media screen and (max-width: 520px) {
  #top .search {
    margin-bottom: calc((8px * 6) * 0.5);
  }
}

#top .search .search-headline {
  position: relative;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}

#top .search .search-headline em {
  color: #c80952;
  font-size: 20px;
}

#top .search .cate-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

#top .search .cate-list__item {
  width: calc(33.33% - (16px * 2 / 3));
  overflow: hidden;
  border-radius: 6px;
  background: #30669B;
}

@media screen and (max-width: 520px) {
  #top .search .cate-list__item {
    width: calc(50% - (16px / 2));
  }
}

#top .search .cate-list__item a .cate-vsl {
  overflow: hidden;
}

#top .search .cate-list__item a .cate-vsl img {
  transition: 0.3s ease-out;
}

#top .search .cate-list__item a .cate-dtl {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 12px;
  color: #ffffff;
  font-size: 12px;
}

@media screen and (max-width: 520px) {
  #top .search .cate-list__item a .cate-dtl {
    font-size: 10px;
  }
}

#top .search .cate-list__item a .cate-dtl::before {
  content: '';
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 8px;
  height: 8px;
  background: linear-gradient(to bottom right, transparent 50%, #ffffff 50%);
}

@media screen and (max-width: 768px) {
  #top .search .cate-list__item a .cate-dtl::before {
    right: 6px;
    bottom: 6px;
    width: 6px;
    height: 6px;
  }
}

#top .search .cate-list__item a .cate-dtl em {
  display: block;
  font-size: 14px;
  line-height: 1.2;
}

@media screen and (max-width: 520px) {
  #top .search .cate-list__item a .cate-dtl em {
    font-size: 12px;
  }
}

#top .search .cate-list__item a:hover .cate-vsl img {
  transform: scale(1.1);
}

#top .concept .concept-headline {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}

#top .concept .concept-headline em {
  color: #c80952;
  font-size: 20px;
}

#top .concept .concept-dtl {
  position: relative;
  max-width: 768px;
}

#top .concept .concept-dtl::before {
  content: '';
  position: absolute;
  top: 0;
  left: calc(100% + 24px);
  z-index: -1;
  width: 420px;
  height: 360px;
  background: url("../..//img/common/bg1.png") center/contain no-repeat;
}

@media screen and (max-width: 768px) {
  #top .concept .concept-dtl::before {
    display: none;
  }
}

#top .concept .concept-dtl .concept-headline {
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
}

#top .concept .concept-dtl .concept-headline::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 24px;
  height: 1px;
  background: #333333;
}

#top .concept .concept-dtl .concept-headline em {
  color: #c80952;
  font-size: 28px;
}

@media screen and (max-width: 520px) {
  #top .concept .concept-dtl .concept-headline em {
    font-size: 24px;
  }
}

#top .concept .concept-dtl .concept-txt {
  text-align: justify;
  line-height: 2.0;
}
