//変数設定の読み込み
@import "../common/_variable.scss";

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//
// トップ（SP）
//
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//------------------------------//
// リセット、ベース設定
//------------------------------//
  #top {
    // リセット
    html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote::before,blockquote::after,q::before,q::after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}input,select,button,textarea{margin:0;padding:0;background:transparent;border:none;border-radius:0;outline:none;appearance:none;}select::-ms-expand{display:none;}*,*::before,*::after{box-sizing:border-box;}
    // ベース調整
    line-height: 1.6;
    letter-spacing: 0.05em;
    a {color: $blk;text-decoration: none;overflow-wrap: break-word;transition: 0.3s ease-out;&:visited{color:$blk;}&:hover{color:$blk;}&:active {color:$blk;}&[href*="tel:"]{text-decoration:none;pointer-events:none;cursor:default;}}
    img {display:block;max-width:100%;height:auto;vertical-align:bottom;&:not(.no-fit){width:100%;}}
    .list__item{margin-left:1em;text-indent:-1em;&:not(:nth-last-of-type(1)){margin-bottom:4px;}&::before {content:'・';}}
  }

//------------------------------//
// 商品リンク
//------------------------------//
  #top {
    .search {
      @include mb(x6);
      .search-headline {
        position: relative;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: bold;
        em {
          color: $red;
          font-size: 20px;
        }
        font-family: $ff-min;
      }
      .cate-list {
        @include flex();
        gap: 16px;
        &__item {
          width: calc(33.33% - (16px * 2 / 3));
          overflow: hidden;
          border-radius: 6px;
          background: $blu;
          @include break(xs) {
            width: calc(50% - (16px / 2));
          }
          a {
            .cate-vsl {
              overflow: hidden;
              img {
                transition: 0.3s ease-out;
              }
            }
            .cate-dtl {
              position: relative;
              @include flex(center,center,column);
              padding: 8px 12px;
              color: $wht;
              font-size: 12px;
              @include break(xs) {
                font-size: 10px;
              }
              &::before {
                content: '';
                @include pos($r:8px,$b:8px);
                width: 8px;
                height: 8px;
                background: linear-gradient( to bottom right, $tra 50%, $wht 50%);
                @include break(s) {
                  right: 6px;
                  bottom: 6px;
                  width: 6px;
                  height: 6px;
                }
              }
              em {
                display: block;
                font-size: 14px;
                line-height: 1.2;
                @include break(xs) {
                  font-size: 12px;
                }
              }
            }
            &:hover {
              .cate-vsl {
                img {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
  }

//------------------------------//
// 年齢に抗わず、自分に合った美しさを
//------------------------------//
  #top {
    .concept {
      .concept-headline {
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: bold;
        em {
          color: $red;
          font-size: 20px;
        }
        font-family: $ff-min;
      }
      .concept-dtl {
        position: relative;
        max-width: 768px;
        &::before {
          content: '';
          @include pos($t:0,$l:calc(100% + 24px),$z:-1);
          width: 420px;
          height: 360px;
          background: printUrl('/img/common/bg1.png') center / contain no-repeat;
          @include break(s) {
            display: none;
          }
        }
        .concept-headline {
          position: relative;
          margin-bottom: 32px;
          padding-bottom: 10px;
          font-size: 24px;
          font-weight: bold;
          &::before {
            content: '';
            @include pos($l:0,$b:0);
            width: 24px;
            height: 1px;
            background: $blk;
          }
          em {
            color: $red;
            font-size: 28px;
            @include break(xs) {
              font-size: 24px;
            }
          }
          font-family: $ff-min;
        }
        .concept-txt {
          text-align: justify;
          line-height: 2.0;
        }
      }
    }
  }